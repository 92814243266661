import Dashboard from "./page/dashboard/Dashboard";
import { createBrowserRouter,RouterProvider, Navigate } from "react-router-dom";
import { DarkModeContext } from "./context/modeContext";
import { useContext } from 'react';
import { AuthContext } from './controllers/authControllers';
import Signup from "./page/signup/Signup";
import Login from "./page/login/Login";
import AddMusic from "./page/addMusic/addMusic";
import Home from "./page/home/Home";
import Label from "./page/Label/Label";
import Contact from "./page/contact/Contact";
import Services from "./page/services/Services";
import Admin from "./page/admin/admin";
import Propos from "./page/propos/Propos";
import Retrait from "./page/retrait/Retrait";
import Settings from "./page/settings/Settings";
import Blog from "./page/blog/blog";
import ManageData from "./page/manageData/ManagaData";
import VerifyUser from "./page/verifyUser/VerifyUser";
import ForgotPassword from "./page/forgotPassword/forgotPassword";
import ChangePassword from "./page/signup/changePassword";
import Confidentialite from "./page/confidentialites/Confidentialites";
import PolitiqueConfidentialites from "./page/politiqueConfidentialites/PolitiqueConfidentialites";
import ServiceDistributions from "./page/serviceDistributions/ServiceDistributions";
import TermeConditions from "./page/termeConditions/TermeConditions";
import PolitiqueVentes from "./page/politiqueVentes/PolitiqueVentes";
import Artistes from "./page/artistes/Artistes";
import LoginAdmin from "./page/admin_login/Login";
import MaisonProduction from "./page/maisonProduction/MaisonProduction";
import DetailMaisonProduction from "./page/detailMaisonProduction/DetailMaisonProduction";
import SignupChallenge from "./page/signup/SignupChallenge";
import SendEmail from "./page/mail/SendEmail";
import Evenements from "./page/evenements/Evenements";
import AddMusicTest from "./page/addMusic/addMusicTest";
import Podcast from "./page/podcast/Podcast";
import AddPodcast from "./page/addPodcast/addPodcast";
import DetailBlog from "./page/detailBlog/DetailBlog";
import PayementAccept from "./page/payement/Payement";
import PayementCancel from "./page/payement/PayementCancel";

function App() {
  const { darkMode } = useContext(DarkModeContext);
  const { currentUser } = useContext(AuthContext);

  const ProtectedRoute = ({ children }) => {
    if (!currentUser) {
      return  window.location.href = '/login';
    }

    return children;
  };


  const router_fr = createBrowserRouter([
    {
      path: "/dashboard",
      element:(
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      )
    },
    {
      path: "/retrait",
      element:
          (!currentUser ? <Login /> : <Retrait />)
    },
    {
      path: "/adminRetrait",
      element:
          (!currentUser ? <Login /> : <Retrait page="admin"/>)
    },
    {
      path: "/event",
      element: <Evenements />
    },
    {
      path: "/activation",
      element:<VerifyUser />
    },
    {
      path: "/manageData",
      element:
          (!currentUser ? <LoginAdmin /> : <ManageData/>)
    },
    {
      path: "/sendMail",
      element:
          (!currentUser ? <LoginAdmin /> : <SendEmail />)
    },
    {
      path: "/artistes",
      element:
          (!currentUser ? <LoginAdmin /> : <Artistes />)
    },
    {
      path: "/podcasts",
      element: <Podcast />
    },
    {
      path: "/login",
      element: !currentUser ? <Login /> : <Navigate to='/Dashboard' />
    },
    {
      path: "/lithyusMusicOnlyOnlineAdminDashbord",
      element: 
      // !currentUser ? <LoginAdmin /> : 
        <Admin />
    },
    {
      path: "/accueil",
      element: <Home />,
    },
    {
      path: "/settings",
      element: <Settings />,
    },
    {
      path: "/adminSettings",
      element: <Settings page='admin'/>,
    },
    {
      path: "/contact",
      element: <Contact />,
    },
    {
      path: "/addPodcast",
      element: <AddPodcast />,
    },
    {
      path: "/label",
      element: <Label />,
    },
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/signup",
      element: <Signup />,
    },
    {
      path: "/signupMusicMasterChallenge",
      element: <SignupChallenge />,
    },
    {
      path: "/confidentialites",
      element: <Confidentialite />,
    },
    {
      path: "/politiqueConfidentialites",
      element: <PolitiqueConfidentialites />,
    },
    {
      path: "/serviceDistributions",
      element: <ServiceDistributions />,
    },
    {
      path: "/termeConditions",
      element: <TermeConditions />,
    },
    {
      path: "/politiqueVentes",
      element: <PolitiqueVentes />,
    },
    {
      path: "/changePassword",
      element: <ChangePassword />,
    },
    {
      path: "/addMusic",
      element: (
        <ProtectedRoute>
          <AddMusic />
        </ProtectedRoute>)
    },
    {
      path: "/addMusicTest",
      element: (
        <ProtectedRoute>
          <AddMusicTest />
        </ProtectedRoute>)
    },
    {
      path: "/forgotPassword",
      element: <ForgotPassword />,
    },
    {
      path: "/propos",
      element:<Propos/>
    },
    {
      path: "/services",
      element: <Services />
    },
    {
      path: "/blog",
      element: <Blog />
    },
    {
      path: "/detailBlog/:id",
      element: <DetailBlog />
    },
    {
      path: "/maisonProduction",
      element: <MaisonProduction />
    },
   {
     path: "/detailMaisonProduction/:id",
     element: <DetailMaisonProduction />
    },
   {
     path: "/payement-success",
     element: <PayementAccept />
    },
   {
     path: "/payement-cancel",
     element: <PayementCancel />
    },

  ])
  return (
      <div className={`theme-${darkMode ? "dark" : "light"}`}>
        <RouterProvider router = {router_fr} />  
      </div>
  );
}

export default App;
