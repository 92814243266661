import 'bootstrap/dist/css/bootstrap.css';
import React, { useState, useContext, useEffect } from "react";
import AreaChart from "../../components/Chart/AreaChart";
import { AuthContext } from "../../controllers/authControllers"
import axios from 'axios'
import { useQuery } from "react-query";
import ArtistesMusic from '../../components/music/artistesMusic'
import Transactions from '../../components/transactions/transactions';
import LeftSide from '../../components/Slide/LeftSide';
import Login from '../login/Login';
import Royalties from '../../components/royalties/Royalties';
import Loader from '../../components/loading/Loader';
import MostGender from '../../components/mostGender/MostGender';

import MoreHorizSharpIcon from '@mui/icons-material/MoreHorizSharp';
import HeadsetSharpIcon from '@mui/icons-material/HeadsetSharp';
import AttachMoneySharpIcon from '@mui/icons-material/AttachMoneySharp';
import AssignmentSharpIcon from '@mui/icons-material/AssignmentSharp';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';

import icon2 from '../../assets/icon/Depenses.png'
import icon10 from '../../assets/icon/revenus.png'
import "./dashboard.scss"

function Dashboard(){
    const currentUser = useContext(AuthContext);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [resendMessage, setResendMessage] = useState(false);
    const [noWait, setNoWait] = useState(false);

    useEffect(() => {
        const checkAuth = async () => {
          try {
            await axios.post('/api/users/verify', {}, {
                withCredentials:true,
            });
            setIsAuthenticated(true);
          } catch (error) {
            console.error('Erreur lors de la vérification de l\'authentification', error);
          }
        };
    
        checkAuth();
    }, []);

    useEffect(() => {
        const checkAuth = async () => {
          try {
            const res = await axios.post('/api/users/checkIsVerify', {}, {
                withCredentials:true,
            });
            if (res.data.isVerified)
            {
                setIsVerified(true);
            }
          } catch (error) {
            console.error('Erreur lors de la vérification de l\'authentification', error);
          }
        };
    
        checkAuth();
    }, []);


    const musicData = useQuery(["music", currentUser.currentUser.idMusicien], () =>
        
        axios.get(`/api/music/getMusic?musicienId=${currentUser.currentUser.idMusicien}`, {
            withCredentials:true
        }).then((res) => {    
            return res.data;
        })
    )
    const transactionsData = useQuery(["transactions", currentUser.currentUser.idMusicien], () =>
        
        axios.get(`/api/transactions/getArtistesTransactions?musicienId=${currentUser.currentUser.idMusicien}`, {
            withCredentials:true
        }).then((res) => {    
            return res.data;
        })
    )

    let retrait = 0;

    if (transactionsData.data) {
        retrait = Math.floor(transactionsData.data.reduce((sum, item) => {
            const {Montant} = item;
            const sumOfServices = Montant;
            return sum + sumOfServices;
        }, 0) * 100) / 100;
    }
    const streamData = useQuery(["stream", currentUser.currentUser.idMusicien], () =>
        
        axios.get(`/api/royalties/getStream?musicienId=${currentUser.currentUser.idMusicien}`, {
            withCredentials:true
        }).then((res) => {    
            return res.data;
        })
    )
    const royaltiesData = useQuery(["royalties", currentUser.currentUser.idMusicien], () =>
        
        axios.get(`/api/royalties/getRoyalties?musicienId=${currentUser.currentUser.idMusicien}`, {
            withCredentials:true
        }).then((res) => {    
            return res.data;
        })
    )

    let revenue = 0;

    if (royaltiesData.data) {
        revenue = Math.floor(royaltiesData.data.reduce((sum, item) => {
            const {idroyalties, music_title, musicien_id, ...others} = item;
            const sumOfServices = Object.values(others).reduce((acc, value) => acc + value, 0);
            return sum + sumOfServices;
        }, 0) * 100) / 100;
    }
    const monthData = useQuery(["monthData", currentUser.currentUser.idMusicien], () =>
        
        axios.get(`/api/data/getMonthData?musicienId=${currentUser.currentUser.idMusicien}`, {
            withCredentials:true
        }).then((res) => {    
            return res.data;
        })
    )
    const mostGender = useQuery(["mostGender", currentUser.currentUser.idMusicien], () =>
        
        axios.get(`/api/music/getMostGender`, {
            withCredentials:true
        }).then((res) => {    
            return res.data;
        })
    )
    const musicDataAll = useQuery(["musicAll"], () =>
        
        axios.get(`/api/music/getAllMusic`, {
            withCredentials:true
        }).then((res) => {    
            return res.data;
        })
    )

    let result;
    if (monthData.data){

        result = monthData.data.reduce((acc, item) => {
            const { month, service, stream } = item;
            const key = `${month}_${service}`;
            
            if (acc[key]) {
              acc[key] += stream;
            } else {
              acc[key] = stream;
            }
            return acc;
        }, {});
    }
    let totalValue = 0;
    let minValue = 0;
    let maxValue = 0;
    let meanValue = 0;
    if (result) {
        totalValue = Object.values(result).reduce((sum, value) => sum + value, 0);
        const values = Object.values(result) || 0;
        if (values.length){

            minValue = Math.min(...values);
            maxValue = Math.max(...values);
            meanValue = Math.floor(totalValue / Object.keys(result).length);
        }else{
            minValue = 0;
            maxValue = 0;
            meanValue = 0;
        }
    }
    const ResendMail = async() => {
        try {
            // console.log("test test test");
            const res = await axios.post("/api/email/sendVerificationEmail", currentUser.currentUser);
            if (res.data.message){
                setTimeLeft(300)
                setResendMessage(true);
                setTimeout(() => {
                    setResendMessage(false);
                }, 300000);
                setNoWait(true)
            }else{
                setTimeLeft(Math.floor(res.data / 1000))
                setResendMessage(true);
                setTimeout(() => {
                    setResendMessage(false);
                }, res.data);
            }
          } catch (error) {
            console.log(error)
          }
    }

    const [timeLeft, setTimeLeft] = useState(0); // Durée du compte à rebours en secondes

    useEffect(() => {
        if (timeLeft > 0) {
        // Mettre à jour le compte à rebours toutes les secondes
        const countdownTimer = setTimeout(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        // Nettoyer le compte à rebours lorsque le composant est démonté
        return () => clearTimeout(countdownTimer);
        }
    }, [timeLeft]);

    if (isAuthenticated){
        if (isVerified)
        {

            return(
                
                <div className="dashboard">
                    <LeftSide page='dashboard'/>
                    <div className="rightSide">
                        <div className="chiffre">
                            
                            <div className="content">
                                <div className="box">
                                    <div className="boxContent">
                                        <div className="iconContent">
                                            <img src={icon10} alt="" />
                                        </div>
                                        <div className="descContent">
                                            <span>
                                                Revenue
                                            </span>
                                            <h4>
                                                {revenue} $
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="boxContent">
                                        <div className="iconContent">
                                            <img src={icon2} alt="" />
                                        </div>
                                        <div className="descContent">
                                            <span>
                                                Retrait
                                            </span>
                                            <h4>
                                                {retrait} $
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="boxContent">
                                        <div className="iconContent">
                                            <HeadsetSharpIcon />
                                        </div>
                                        <div className="descContent">
                                            <span>
                                                Ecoutes
                                            </span>
                                            <h4>
                                                {totalValue}
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content">
                                <div className="box">
                                    <div className="boxContent">
                                        <div className="iconContent">
                                            <AttachMoneySharpIcon />
                                        </div>
                                        <div className="descContent">
                                            <span>
                                                Votre solde
                                            </span>
                                            <h4>
                                                {Math.floor((revenue - retrait) * 100) / 100} $
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="boxContent">
                                        <div className="iconContent">
                                            <AssignmentSharpIcon />
                                        </div>
                                        <div className="descContent">
                                            <span>
                                                Depenses
                                            </span>
                                            <h4>
                                                
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="boxContent">
                                        <div className="iconContent">
                                            <MusicNoteIcon />
                                        </div>
                                        <div className="descContent">
                                            <span>
                                                Musiques Totales
                                            </span>
                                            <h4>
                                                {musicData.data ? musicData.data.length:0}
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="music">
                            <div className="title">
                                New Releases
                            </div>
                            <div className="content">
                                <div className="top">
                                    <div className="titre">
                                        Titre
                                    </div>
                                    <div className="date">
                                        Date
                                    </div>
                                    <div className="status">
                                        Status
                                    </div>
                                    <div className="promute">
                                        Promotion
                                    </div>
                                </div>
                                <hr />
                                <div className="contentBox">
                                    {musicData.error
                                        ?"Une erreur s'est produit"
                                        :musicData.isLoading
                                            ?<Loader type="spiner"/>
                                            : musicData.data.length
                                                ? musicData.data.map((music) =><ArtistesMusic music={music} key={music.idmusic}/>)    
                                            : <span style={{
                                                textAlign:'center',
                                                color:'white'
                                            }}>Pas encore de Music</span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="chartContainer">
                            <div className="revenu">
                                <div className="top">
                                    <AutoGraphIcon className="icon"/>
                                </div>
                                <div className="chart">
                                {
                                    result
                                        ?
                                            <AreaChart chartData = {result}/>
                                        : <span>Pas de donnees</span>
                                }
                                </div>
                                <div className="bottom">
                                    <div className="stats">
                                        <div className="content">
                                            <span>Valeur Totale</span>
                                            <h4>
                                                {totalValue}
                                            </h4>
                                        </div>
                                        <div className="content">
                                            <span>Plus Petite Valeur</span>
                                            <h4>{minValue}</h4>
                                        </div>
                                        <div className="content">
                                            <span>Plus grande Valeur</span>
                                            <h4>{maxValue}</h4>
                                        </div>
                                        <div className="content">
                                            <span>Valeur moyenne</span>
                                            <h4>{meanValue}</h4>
                                        </div>
                                    </div>
                                    {/* <div className="objectif">
                                        <span>Objectif visé</span>
                                        <Bar percentage='80' onChange={handleChange}/>
                                    </div> */}
                                </div>
                            </div>
                            {/* <div className="weekly">
                                <div className="top">
                                    <AutoGraphIcon className="icon"/>
                                </div>
                                <div className="chart">
                                    <BarChart status="DailyListen"/>
                                </div>
                            </div> */}
                            <div className="topGender">
                                <div className="top">
                                    <span>Top 5 des genres</span>
                                    <div className="TopGenderIcon">
                                        <MoreHorizSharpIcon />
                                    </div>
                                </div>
                                {mostGender.error
                                    ?"Une erreur s'est produit"
                                    :mostGender.isLoading
                                        ?<Loader type="spiner"/>
                                        : mostGender.data.length
                                            ? <MostGender gender = {mostGender} music={musicDataAll} />
                                            : <span style={{
                                                textAlign:'center',
                                                color:'white'
                                            }}>Pas encore de musiques</span>
                                }
                            </div>
                        </div>
                        {/* <div className="traffic">
                            <div className="dailyTraffic">
                                <div className="top">
                                    <span>Traffic quotidien</span>
                                    <div className="topGenderRight">
                                        <ArrowDropUpSharpIcon />
                                        <span>+2.45%</span>
                                    </div>
                                </div>
                                <div className="topGenderBottom">
                                    <h4>2.579</h4>
                                    <span>Visitors</span>
                                </div>
                                <div className="topGenderBottomChart">
                                    <BarChart status='traffic'/>
                                </div>
                            </div>
                        </div> */}
                        <div className="royalties">
                            <div className="title">
                                Stream
                            </div>
                            <div className="content">
                                <div className="top">
                                    <div className="royaltiesMusic">
                                        Musiques
                                    </div>
                                    <div className="spotify">
                                        Spotify
                                    </div>
                                    <div className="apple">
                                        Deezer
                                    </div>
                                    <div className="google">
                                        YouTube
                                    </div>
                                    <div className="autre">
                                        Apple Music
                                    </div>
                                    <div className="youtube">
                                        Autres
                                    </div>
                                    <div className="total">
                                        Total
                                    </div>
                                </div>
                                <hr />
                                <div className="contentBox">
                                    {streamData.error
                                        ?"Une erreur s'est produit"
                                        :streamData.isLoading
                                            ?<Loader type="spiner"/>
                                            : streamData.data.length
                                                ? streamData.data.map((royalties) =><Royalties royalties={royalties} key={royalties.idstream}/>)   
                                                : <span style={{
                                                    textAlign:'center',
                                                    color:'white'
                                                }}>Pas encore de Transaction</span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="royalties">
                            <div className="title">
                                Royalties
                            </div>
                            <div className="content">
                                <div className="top">
                                    <div className="royaltiesMusic">
                                        Musiques
                                    </div>
                                    <div className="spotify">
                                        Spotify
                                    </div>
                                    <div className="apple">
                                        Deezer
                                    </div>
                                    <div className="google">
                                        YouTube
                                    </div>
                                    <div className="autre">
                                        Apple Music
                                    </div>
                                    <div className="youtube">
                                        Autres
                                    </div>
                                    <div className="total">
                                        Total
                                    </div>
                                </div>
                                <hr />
                                <div className="contentBox">
                                    {royaltiesData.error
                                        ?"Une erreur s'est produit"
                                        :royaltiesData.isLoading
                                            ?<Loader type="spiner"/>
                                            : royaltiesData.data.length
                                                ? royaltiesData.data.map((royalties) =><Royalties royalties={royalties} key={royalties.idroyalties}/>)   
                                                : <span style={{
                                                    textAlign:'center',
                                                    color:'white'
                                                }}>Pas encore de Transaction</span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="withdrawal">
                            <div className="title">
                                Retrait
                            </div>
                            <div className="content">
                                <div className="top">
                                    <div className="id">
                                        ID
                                    </div>
                                    <div className="montant">
                                        Montant
                                    </div>
                                    <div className="methodes">
                                        Methodes
                                    </div>
                                    <div className="status">
                                        Status
                                    </div>
                                </div>
                                <hr />
                                <div className="contentBox">
                                {transactionsData.error
                                        ?"Une erreur s'est produit"
                                        :transactionsData.isLoading
                                            ?<Loader type="spiner"/>
                                            : transactionsData.data.length
                                                ? transactionsData.data.map((transactions) =><Transactions transactions={transactions} key={transactions.idtransactions}/>)   
                                                : <span style={{
                                                    textAlign:'center',
                                                    color:'white'
                                                }}>Pas encore de Transaction</span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="copyright">
                            <span>© 2024 Lithyus Music Group, LLC. Tous droits réservés.</span>
                        </div>
                    </div>
                </div>
                
            )
        }else{
            return (

                <div className="dashboard">
                    <LeftSide page='notVerify'/>
                    {
                        resendMessage
                            ?
                                <div>
                                    {
                                        !noWait
                                            ?
                                                <div className="messageToVerifiedDisable">
                                                    Veillez consulter votre adresse mail pour verifier votre compte
                                                    <div>
                                                        <button disabled onClick={ResendMail}>Renvoyer le mail</button>
                                                        <div>Veillez attendre avant de renvoyer un autre mail {Math.floor(timeLeft / 60)} : {timeLeft % 60}</div>
                                                    </div>
                                                </div>
                                            :
                                            <div className="messageToVerifiedDisable">
                                                Veillez consulter votre adresse mail pour verifier votre compte
                                                <div>
                                                    <button disabled onClick={ResendMail}>Renvoyer le mail</button>
                                                    <div style={{color:'green'}}>Email Renvoyé avec succès:</div>
                                                    <div>Réessayer dans {Math.floor(timeLeft / 60)} : {timeLeft % 60}</div>
                                                </div>
                                            </div>
                                    }
                                </div>
                            :
                                <div className="messageToVerified">
                                    Veillez consulter votre adresse mail pour verifier votre compte
                                    <button onClick={ResendMail}>Renvoyer le mail</button>
                                </div>

                    }
                </div>
            )
        }
    }else {
        // Redirection vers la page de connexion
        return <Login />
    }
}

export default Dashboard;