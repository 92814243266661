import React, {useState} from "react";
import "./maisonProduction.scss";
import NavBar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

import {useNavigate} from 'react-router-dom';
/*import img1 from "../../assets/26783.jpg";
import img2 from "../../assets/Congolais.png";
import img3 from "../../assets/JCB.png";
import img4 from "../../assets/telephone-icone-musique-casque-fond-flou-concept-ecoute-musique-espace-copie.jpg";
import img5 from "../../assets/portrait-of-woman-singing-on-microphone.jpg";*/
import prod_31 from "../../assets/maison_production/31 prod.png";
import lithyus from "../../assets/maison_production/logo.png";
import artmony from "../../assets/maison_production/artmony.png";
import bkmusic from "../../assets/maison_production/bk musiic.png";
import bng from "../../assets/maison_production/bng_studio.png";
import destiny from "../../assets/maison_production/destiny.png";
//import doudou from "../../assets/maison_production/doudou_starboy.png";
import fmb from "../../assets/maison_production/fmb_prod.png";
import hauteur from "../../assets/maison_production/hauteur prod.png";
import victory from "../../assets/maison_production/victory_prod.png";
import voierouge from "../../assets/maison_production/voie_rouge.png";
import contact from "../contact/Contact";


const MaisonProduction = () => {


    const Card = ({title, topic, content, contact, imagePath, id, imagePath2, imagePath3, imagePath4}) => {
        const navigate = useNavigate(); // Use useNavigate instead of useHistory

        const [isFullContentShown, setIsFullContentShown] = useState(false);
        const toggleContent = () => {
            setIsFullContentShown((prevState) => !prevState);
        };

        const handleEnSavoirPlusClick = () => {


            // Redirect to the details page with the card's data
            navigate(`/detailMaisonProduction/${id}`, {
                state: {
                    title,
                    topic,
                    content,
                    contact,
                    imagePath,
                    id,
                    imagePath2,
                    imagePath3,
                    imagePath4,
                },
            });
        };


        return (
            <div className="carte" onClick={handleEnSavoirPlusClick}>
                <div className="cover12">
                    <img src={imagePath} alt={title}/>
                </div>
                <div className="image-title12">
                    <p>{title}
                        <br/>
                    </p>
                    <div>
                        {!isFullContentShown && (
                            <button className="button12" onClick={handleEnSavoirPlusClick}>
                                EN SAVOIR PLUS
                            </button>
                        )}
                    </div>
                </div>


            </div>
        );
    };


    const cardData = [
        {
            title: "LITHYUS MUSIC DISTRIBUTION",
            topic: "Distributeur",
            contact: "Contact (WhatsApp) : +17252248456",
            content: "",
            imagePath: lithyus,
        },
        {
            title: " 31PROD",
            topic: "Producteur",
            content: "Nous sommes une maison de production qui a pour but de produire de jeunes " +
                "artistes talentueux qui n'ont peur d'essayer de nouvelles choses. Nous sommes " +
                "independants et travaillons avec des artistes pour produire leur musique. Nous " +
                "collaborons aussi avec les labels de musique afin d'aider les artistes à promouvoir " +
                "leur musique en organisant des concerts et en créant des vidéos musicales.",
            contact: "Contact (WhatsApp) : 34617082062",
            imagePath: prod_31,
        },
        /*{
            title: " ADL",
            topic: "Beatmaker",
            content: "",
            contact: "Contact (WhatsApp) : 22666075341",
            imagePath: img1,
        },*/
        {
            title: " LA VOIE ROUGE(ALASSANE KONFE)",
            topic: "Producteur",
            content: "Nous sommes une maison de production qui a pour but de produire " +
                "de jeunes artistes talentueux qui n'ont peur d'essayer de nouvelles" +
                " choses.Notre clientele se trouve principalement a ouagadougou ou la " +
                "sphere musicale est en pleine croissance et nous voulons faire partie du " +
                "developpement de cette industrie. Nous sommes independants et travaillons " +
                "avec des artistes pour produire leur musique. Nous collaborons aussi avec les" +
                " labels de musique afin d'aider les artistes à promouvoir leur musique en " +
                "organisant des concerts et en créant des vidéos musicales.",
            contact: "Contact (WhatsApp) : +22671409092",
            imagePath: voierouge,
        },
        {
            title: " BK MUSIC (ALEX KANDIA)",
            topic: "Producteur",
            content: "Nous sommes une maison de production qui a pour but de " +
                "produire de jeunes artistes talentueux qui n'ont peur d'essayer de" +
                " nouvelles choses. Nous sommes independants et travaillons avec des artistes " +
                "pour produire leur musique. Nous collaborons aussi avec les labels de musique " +
                "afin d'aider les artistes à promouvoir leur musique en organisant des concerts et " +
                "en créant des vidéos musicales. Contactez-nous pour tout besoin nous restons disponible pour.",
            contact: "Contact (WhatsApp) : 32465705725",
            imagePath: bkmusic,
        },
        /*{
            title: "ANDREY KAM ",
            topic: "Realisateur",
            content: "",
            contact: "Contact (WhatsApp) : 22672891939",
            imagePath: img2,
        },
        {
            title: " ARIEL MAREZ",
            topic: "Arrangeur",
            content: "",
            contact: "Contact (WhatsApp) : 22663750411",
            imagePath: img3,
        },
        {
            title: " AXL",
            topic: "Graphiste",
            content: "",
            contact: "Contact (WhatsApp) : 22673965152",
            imagePath: img4,
        },*/
        {
            title: "HAUTEUR PROD ( BEATMONSTER )",
            topic: "Beatmaker",
            content: "Nous faisons du beatmaking principalement " +
                "pour le rap, le hip-hop ou le RnB contemporain. Nous utilise" +
                " des logiciels et des appareils électroniques pour créer des " +
                "rythmes (des beats) originaux et variés.Nous permettons aux artistes " +
                "de faire sortir de ce rythme qu'ils ont en tete afin de le rendre reel " +
                "et utilisable pour la realisation de leurs projets musicaux." +
                " Nous faisons en sorte que ces beats soient distribuables sur les " +
                "plateformes de streaming. Nous restons disponible pour toute collaboration.",
            contact: "Contact (WhatsApp) : +22656463724",
            imagePath: hauteur,
        },
       /* {
            title: "BEN TAPS ",
            topic: "Beatmaker",
            content: "",
            contact: "Contact (WhatsApp) : 22663417325",
            imagePath: img5,
        },
        {
            title: "BEN-J ON THE BEAT ",
            topic: "Beatmaker",
            content: "",
            contact: "Contact (WhatsApp) : 22672295911",
            imagePath: img4,
        },
        {
            title: " BIG BANG NIGHT",
            topic: "Evenementiel",
            content: "",
            contact: "Contact (WhatsApp) : 22674338717",
            imagePath: img3,
        },
        {
            title: "BILLA TV ",
            topic: "Media",
            content: "",
            contact: "Contact (WhatsApp) : 22665997785",
            imagePath: img2,
        },
        {
            title: "BLACKY CREATIONS",
            topic: "Graphiste",
            content: "",
            contact: "Contact (WhatsApp) : 22656308898",
            imagePath: img1,
        },
        {
            title: "226 MUSIC TV ( BRICO ) ",
            topic: "Media",
            content: "",
            contact: "Contact (WhatsApp) : 22670660120",
            imagePath: img5,
        },
        {
            title: "CHERIF KARAMBIRI ",
            topic: "Manager",
            content: "",
            contact: "Contact (WhatsApp) : 22662595975",
            imagePath: img2,
        },*/
        {
            title: "FMB PROD ( COOPER )",
            topic: "Realisateur",
            content: "Nous sommes une maison de production 360 dans la mesure " +
                "ou nous nous occupons des artistes, de la prise de voix a la " +
                "réalisation du produit final et de la distribution du morceau " +
                "afin de s'assurer d'un processus optimal pour un partage efficace" +
                " des oeuvres artistiques de notre clientele. Nous réalisons aussi" +
                " des clips vidéos selon les envies de la clientele pour un rendu " +
                "memorable et agréable a regarder. A travers ce processus, on permet " +
                "aux artistes de ne pas rester en marge du développement de l'industrie " +
                "musicale, on leur permet de faire parti de cette évolution.",
            contact: "Contact (WhatsApp) : +22660680967",
            imagePath: fmb,
        },
       /* {
            title: "DIDJUIK GENESIS ",
            topic: "Manager",
            content: "",
            contact: "Contact (WhatsApp) : 22660680967",
            imagePath: img1,
        },
        {
            title: " DMS MANAGUA",
            topic: "Manager",
            content: "",
            contact: "Contact (WhatsApp) : 22673490311",
            imagePath: img4,
        },
        {
            title: " DOFF",
            topic: "Beatmaker",
            content: "",
            contact: "Contact (WhatsApp) : 22670711151",
            imagePath: img4,
        },
        {
            title: " DUCARTEL MANADJA",
            topic: "Manager",
            content: "",
            contact: "Contact (WhatsApp) : 22667229143",
            imagePath: img1,
        },
        ,
        {
            title: "EMPEREUR DICKO ",
            topic: "Producteur",
            content: "",
            contact: "Contact (WhatsApp) : 33677457529",
            imagePath: img5,
        },
        {
            title: "GANIYOU ODG ",
            topic: "Realisateur",
            content: "",
            contact: "Contact (WhatsApp) : 22663310150",
            imagePath: img3,
        },*/
        {
            title: " VICTORY PROD  ( GENERAL )",
            topic: "Arrangeur",
            content: "Nous sommes une maison de production qui a pour but" +
                " de creer des beats musicaux ou les perfectionner afin de " +
                "les rendre plus audibles te transferable sur les plateformes " +
                "de streaming, notre prinicipale clientele se voit etre de jeunes" +
                " artistes talentueux qui n'ont pas peur d'essayer de nouvelles choses." +
                " Nous sommes independants et travaillons avec des artistes pour produire " +
                "leur musique. Nous collaborons aussi avec les labels de musique afin d'aider " +
                "les artistes à promouvoir leur musique en organisant des concerts et en créant " +
                "des vidéos musicales.",
            contact: "Contact (WhatsApp) : +22655404292",
            imagePath: victory,
        },
       /* {
            title: "GUY RAY ",
            topic: "Producteur",
            content: "",
            contact: "Contact (WhatsApp) : 22673256029",
            imagePath: img2,
        },
        ,
        {
            title: "Hamed SIBONE ",
            topic: "Promoteur",
            content: "",
            contact: "Contact (WhatsApp) : 22670417652",
            imagePath: img1,
        },
        {
            title: "HH PHOTOGRAPHY ",
            topic: "Photographe",
            content: "",
            contact: "Contact (WhatsApp) : 22651895305",
            imagePath: img3,
        },
        {
            title: " HKPJ SHOOT",
            topic: "Realisateur",
            content: "",
            contact: "Contact (WhatsApp) : 22606346871",
            imagePath: img1,
        },
        {
            title: "STALLION FILMS ( I.BLACKDIAMOND )",
            topic: "Realisateur",
            content: "",
            contact: "Contact (WhatsApp) : 22664758851",
            imagePath: img4,
        },
        {
            title: "STALLION FILMS (JAMAL)",
            topic: "Realisateur",
            content: "",
            contact: "Contact (WhatsApp) : 22671989894",
            imagePath: img5,
        },*/
        {
            title: " ART'MONY ( JEANO ) ",
            topic: "Arrangeur",
            content: "Nous sommes une maison de production qui a pour " +
                "but de creer des beats musicaux ou les perfectionner afin " +
                "de les rendre plus audibles te transferable sur les plateformes " +
                "de streaming, notre prinicipale clientele se voit etre de jeunes " +
                "artistes talentueux qui n'ont pas peur d'essayer de nouvelles choses. " +
                "Nous sommes independants et travaillons avec des artistes pour produire " +
                "leur musique. Nous collaborons aussi avec les labels de musique afin d'aider " +
                "les artistes à promouvoir leur musique en organisant des concerts et en créant " +
                "des vidéos musicales. L'arrangement reste une etape cle de la production musicale.",
            contact: "Contact (WhatsApp) : +22675801534",
            imagePath: artmony,
        },
        /*
        {
            title: " JODALA TV",
            topic: "Media",
            content: "",
            contact: "Contact (WhatsApp) : 22656196345",
            imagePath: doudou,
        },

        {
            title: "OVERLORD (JOSEPH SAWADOGO)",
            topic: "Photographe",
            content: "",
            contact: "Contact (WhatsApp) : 22673067783",
            imagePath: img1,
        },
        {
            title: "K2BSHOOTBEAT ",
            topic: "Arrangeur",
            content: "",
            contact: "Contact (WhatsApp) : 22665735715",
            imagePath: img4,
        },
        {
            title: "KHALIL PICTURE ",
            topic: "Photographe",
            content: "",
            contact: "Contact (WhatsApp) : 22673581232",
            imagePath: img5,
        },
        {
            title: " KHALIL PICTURE (PUB)",
            topic: "Infographe",
            content: "",
            contact: "Contact (WhatsApp) : 22666773767",
            imagePath: img2,
        },
        {
            title: "KRAAZ ",
            topic: "Graphiste",
            content: "",
            contact: "Contact (WhatsApp) : 22675822775",
            imagePath: img5,
        },
        {
            title: " LEO ON THE BEAT",
            topic: "Beatmaker",
            content: "",
            contact: "Contact (WhatsApp) : 22673666253",
            imagePath: img1,
        },
        {
            title: "LOUIS SHINE DIRECTOR ",
            topic: "Realisateur",
            content: "",
            contact: "Contact (WhatsApp) : 22666740270",
            imagePath: img3,
        },
        ,
        {
            title: " MADARA MADPROD",
            topic: "Realisateur 3D",
            content: "",
            contact: "Contact (WhatsApp) : 22652855270",
            imagePath: img2,
        },*/
        {
            title: " BNG MUSIC ( Manager )",
            topic: "Manager",
            content: "Nous notre corps de métier c'est d'aider les jeunes " +
                "artistes a etre accompagné au cours de leur carriere musicale" +
                " en leur permettant de trouver des moyens de promouvoir leur" +
                " musique notamment a tarvers des concerts, des showcases et " +
                "bien d'autres elements afin de les aider a se réaliser pleinement.",
            contact: "Contact (WhatsApp) : +22607020866",
            imagePath: bng,
        },

/*        {
            title: " MG SANTANA",
            topic: "Beatmaker",
            content: "",
            contact: "Contact (WhatsApp) : 22666991716",
            imagePath: img5,
        },

        {
            title: "MOHA29 ",
            topic: "Realisateur",
            content: "",
            contact: "Contact (WhatsApp) : 22665987825",
            imagePath: img1,
        },

        {
            title: "MR. COLMAXX ",
            topic: "Graphiste",
            content: "",
            contact: "Contact (WhatsApp) : 22672349545",
            imagePath: img3,
        },

        {
            title: " MR.JAY",
            topic: "Photographe",
            content: "",
            contact: "Contact (WhatsApp) : 22670985703",
            imagePath: img4,
        },

        {
            title: "OUSBI MANADJA ",
            topic: "Manager",
            content: "",
            contact: "Contact (WhatsApp) : 22666913055",
            imagePath: img2,
        },

        {
            title: "PRESTIGE PHOTOGRAPHIE ",
            topic: "Photographe",
            content: "",
            contact: "Contact (WhatsApp) : 22657303610",
            imagePath: img5,
        },

        {
            title: " RADIO LEGENDE",
            topic: "Radio",
            content: "",
            contact: "Contact (WhatsApp) : 22660395959",
            imagePath: img1,
        },

        {
            title: " RAYWOX",
            topic: "Realisateur",
            content: "",
            contact: "Contact (WhatsApp) : 22671221967",
            imagePath: doudou,
        },

        {
            title: "SHOOT PRO ",
            topic: "Photographe",
            content: "",
            contact: "Contact (WhatsApp) : 22670674960",
            imagePath: img3,
        },

        {
            title: " SLEEN'G",
            topic: "Arrangeur",
            content: "",
            contact: "Contact (WhatsApp) : 22664446939",
            imagePath: img5,
        },
        {
            title: "SULYVAHN VENDETTA ",
            topic: "Beatmaker",
            content: "",
            contact: "Contact (WhatsApp) : 22674676256",
            imagePath: img4,
        },
        {
            title: "SUZ'ART",
            topic: "Photographe",
            content: "",
            contact: "Contact (WhatsApp) : 22662778148",
            imagePath: img1,
        },
        {
            title: "ROAD FILMS ( VITAL BASSINGA )",
            topic: "Realisateur",
            content: "",
            contact: "Contact (WhatsApp) : 22660056565",
            imagePath: img5,
        },
        {
            title: " Thierry DUPPER",
            topic: "Arrangeur",
            content: "",
            contact: "Contact (WhatsApp) : 22670834986",
            imagePath: img1,
        },*/
        {
            title: "TIDIANE (DESTINY PROD) ",
            topic: "Producteur",
            content: "Nous sommes une maison de production 360 dans " +
                "la mesure ou nous nous occupons des artistes, de la prise de " +
                "voix a la réalisation du produit final et de la distribution du " +
                "morceau afin de s'assurer d'un processus optimal pour un partage" +
                " efficace des oeuvres artistiques de notre clientele. A travers " +
                "ce processus, on permet aux artistes de ne pas rester en marge" +
                " du développement de l'industrie musicale, on leur permet de " +
                "faire parti de cette évolution.",
            contact: "Contact (WhatsApp) : +22673828237",
            imagePath: destiny,
        },
        /*
        {
            title: " WILLIAM HARTHMAN",
            topic: "Realisateur",
            content: "",
            contact: "Contact (WhatsApp) : 22660178792",
            imagePath: doudou,
        },
        {
            title: " Willy (STUDIO)",
            topic: "Arrangeur",
            content: "",
            contact: "Contact (WhatsApp) : 22671371969",
            imagePath: img4,
        },
        {
            title: " WIZZ RAPHA",
            topic: "Media",
            content: "",
            contact: "Contact (WhatsApp) : 22676289121",
            imagePath: img2,
        },
        {
            title: " ZAFIRR",
            topic: "Beatmaker",
            content: "",
            contact: "Contact (WhatsApp) : 22677534444",
            imagePath: img3,
        },*/

    ];


    return (
        <div className="main_block">
            <NavBar page={"propos"}/>
            <div className="titre">
            </div>
            <div className="app">
                {cardData.map((card, index) => (
                    <Card
                        key={index}
                        title={card.title}
                        topic={card.topic}
                        content={card.content}
                        contact={card.contact}
                        imagePath={card.imagePath}
                        id={index}
                    />
                ))}
            </div>
            <Footer/>
        </div>

  );
};

export default MaisonProduction;