import { useEffect } from "react";
import axios from "axios";
import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./login.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
function Login() {
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const [loginError, setLoginError] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    const { email, password } = loginData;
    const newErrors = {};

    if (!password) {
      newErrors.password = "Veuillez remplir le champ mot de passe";
    }

    if (!email) {
      newErrors.email = "Veuillez remplir le champ email";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    try {
      // Envoyer les données de connexion au backend
      setLoginError("");
      const response = await axios.post(
        "/api/users/login",
        loginData,
        {
          withCredentials: true,
        }
      );
      setCurrentUser(response.data);
      window.location.href = "/dashboard";
    } catch (error) {
      setLoginError(true)
    }
  };

  // const responseFacebook = (response) => {
  //   console.log(response);
  // };

  // const responseApple = (response) => {
  //   console.log(response);
  // };

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(currentUser));
  }, [currentUser]);

  // console.log(loginData)
  // const otherLogin = (provider) => {
  //   switch (provider) {
  //     case "Google":
  //       console.log("Inscription avec Google");
  //       break;
  //     case "Facebook":
  //       console.log("Inscription avec Facebook");
  //       break;
  //     case "Apple":
  //       console.log("Inscription avec Apple");
  //       break;
  //     default:
  //       console.log("Fournisseur non pris en charge");
  //       break;
  //   }
  // };

  return (
    <React.Fragment>
      <div className="login">
        <Navbar />
        <div className="div-main">
          <div className="div-main21">
            <div className="top-div">
              <div className="left">
                <span className="text">
                  {" "}
                  <span>
                    <FontAwesomeIcon icon={faCheck} className="red-pi-check" />
                  </span>{" "}
                  DISTRIBUTION GRATUITE
                </span>
                <span className="text">
                  <span>
                    <FontAwesomeIcon icon={faCheck} className="red-pi-check" />
                  </span>{" "}
                  MISE EN LIGNE RAPIDE
                </span>
              </div>
              <div className="right">
                <span className="text">
                  <span>
                    <FontAwesomeIcon icon={faCheck} className="red-pi-check" />
                  </span>{" "}
                  DONNÉES EN TEMPS RÉELS
                </span>
                <span className="text">
                  <span>
                    <FontAwesomeIcon icon={faCheck} className="red-pi-check" />
                  </span>{" "}
                  SERVICE CLIENT EFFICACE
                </span>
              </div>
            </div>
            <div className="centered-div">
              <div className="form-container">
              {
                loginError
                  ? <div style={{color:'red', textAlign:'center'}}>Email ou mot de passe incorrect</div>
                  :null
              }
                <div>
                  <input
                    type="email"
                    placeholder="Adresse email"
                    value={loginData.email}
                    onChange={handleInputChange}
                    name="email"
                  />
                  {errors.email && (
                    <span className="error">{errors.email}</span>
                  )}
                </div>

                <div>
                  <input
                    type="password"
                    placeholder="Mot de passe"
                    value={loginData.password}
                    onChange={handleInputChange}
                    name="password"
                  />
                  {errors.password && (
                    <span className="error">{errors.password}</span>
                  )}
                </div>

                <div className="butom">
                  <button className="button-login" onClick={handleLogin}>
                    Se connecter
                  </button>
                  <NavLink to='/forgotPassword'>Mot de passe oublié</NavLink>
                </div>
                <div className="no-account">
                  Vous n'avez pas de compte ?{" "}
                  <Link className="button" to="/signup">
                    Créer un compte
                  </Link>
                </div>
              </div>
              &nbsp; &nbsp; &nbsp; &nbsp;
              {/* <div className="form-container">
                <div className="provider-button">
                  <GoogleOAuthProvider clientId="431919717510-c5e3qrht4s1eltrf1dkvt864u6i1nsbv.apps.googleusercontent.com">
                    <GoogleLogin
                      onSuccess={(credentialResponse) => {
                        const details = jwt_decode(
                          credentialResponse.credential
                        );
                        console.log(details);
                        // console.log(credentialResponse);
                        Navigate('/dashboard');
                      }}
                      onError={() => {
                        console.log("Login Failed");
                      }}
                    />
                  </GoogleOAuthProvider>

                  <button
                    onClick={() => otherLogin("Google")}
                    className="provider-button"
                  >
                    <img src={logoGoogle} alt="Google" className="logo-icon" />
                    <span className="button-text">
                      Se connecter avec Google
                    </span>
                  </button>
                </div>
                <div>
                   <FacebookLogin
                    appId="175992828784976"
                    //autoLoad
                    callback={responseFacebook}
                    onSuccess={(response)=>{
                      console.log("success", response);
                    }}
                    onFail={(error)=>{
                      console.log("failed", error);
                    }}
                    onProfileSuccess={(response)=>{
                      console.log("Profile", response);
                    }}
                    render={(renderProps) => (
                      <button
                        onClick={renderProps.onClick}
                        className="provider-button"
                      >
                        <img
                          src={logoFacebook}
                          alt="Facebook"
                          className="logo-icon"
                        />
                        <span className="button-text">
                          Se connecter avec Facebook
                        </span>
                      </button>
                    )}
                  />
                  <button
                    onClick={() => otherLogin("Facebook")}
                    className="provider-button"
                  >
                    <img
                      src={logoFacebook}
                      alt="Facebook"
                      className="logo-icon"
                    />
                    <span className="button-text">
                      Se connecter avec Facebook
                    </span>
                  </button>
                </div>
                <div>
                   <AppleLogin
                    clientId="abdoultraodev@gmail.com"
                    redirectURI="https://redirectUrl.com"
                    callback={responseApple}
                    render={(renderProps) => (
                      <button
                        onClick={renderProps.onClick}
                        className="provider-button"
                      >
                        <img
                          src={logoApple}
                          alt="Apple"
                          className="logo-icon"
                        />
                        <span className="button-text">
                          Se connecter avec Apple
                        </span>
                      </button>
                    )}
                  />
                  <button
                    onClick={() => otherLogin("Apple")}
                    className="provider-button"
                  >
                    <img src={logoApple} alt="Apple" className="logo-icon" />
                    <span className="button-text">Se connecter avec Apple</span>
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <Footer />
      </div>
      
    </React.Fragment>
  );
}

export default Login;
