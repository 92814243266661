import React, {useState} from 'react';
import { useQuery } from 'react-query'
import axios from 'axios'
import "./podcast.scss";
import {AudioPlayer} from 'react-audio-play';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import podcast1 from "../../assets/podcasts/Burna_Boy_-_Ye_[Official_Music_Video].m4a";
import podcast2 from "../../assets/podcasts/podcast3.mp3";
import img1 from "../../assets/26783.jpg";
import NavBar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

// const podcasts = [{
//     id: 1,
//     title: "La soirée des influenceurs en Guinée",
//     description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam, sed diam voluptua.",
//     audioSrc: podcast1,
//     img: img1,
//     details: ["Episode 4", "# Posté le 11 Mars 2024", "Influenceurs / Mode / Burkina Faso"]
// }, {
//     id: 2,
//     title: "La soirée des influenceurs en Guinée22",
//     description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam, sed diam voluptua.",
//     audioSrc: podcast2,
//     img: img1,
//     details: ["Episode 4", "# Posté le 11 Mars 2024", "Influenceurs / Mode / Burkina Faso"]
// }, {
//     id: 3,
//     title: "La soirée des influenceurs en Guinée",
//     description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam, sed diam voluptua.",
//     audioSrc: podcast1,
//     img: img1,
//     details: ["Episode 4", "# Posté le 11 Mars 2024", "Influenceurs / Mode / Burkina Faso"]
// }, {
//     id: 4,
//     title: "La soirée des influenceurs en Guinée",
//     description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam, sed diam voluptua.",
//     audioSrc: podcast1,
//     img: img1,
//     details: ["Episode 4", "# Posté le 11 Mars 2024", "Influenceurs / Mode / Burkina Faso"]
// }, {
//     id: 5,
//     title: "La soirée des influenceurs en Guinée",
//     description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam, sed diam voluptua.",
//     audioSrc: podcast1,
//     img: img1,
//     details: ["Episode 4", "# Posté le 11 Mars 2024", "Influenceurs / Mode / Burkina Faso"]
// }, {
//     id: 6,
//     title: "La soirée des influenceurs en Guinée",
//     description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam, sed diam voluptua.",
//     audioSrc: podcast1,
//     img: img1,
//     details: ["Episode 4", "# Posté le 11 Mars 2024", "Influenceurs / Mode / Burkina Faso"]
// }, {
//     id: 7,
//     title: "La soirée des influenceurs en Guinée",
//     description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam, sed diam voluptua.",
//     audioSrc: podcast1,
//     img: img1,
//     details: ["Episode 4", "# Posté le 11 Mars 2024", "Influenceurs / Mode / Burkina Faso"]
// },];

const emissions = [{
    id: 1,
    title: "C'est louche !!",
    autor: "Tindano Ibrahim",
    description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam, sed diam voluptua.",
    audioSrc: podcast1,
    img: img1,
    details: ["Episode 4", "# Posté le 11 Mars 2024", "Influenceurs / Mode / Burkina Faso"]
}, {
    id: 2,
    title: "C'est louche !!",
    autor: "Tindano Ibrahim",
    description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam, sed diam voluptua.",
    audioSrc: podcast1,
    img: img1,
    details: ["Episode 4", "# Posté le 11 Mars 2024", "Influenceurs / Mode / Burkina Faso"]
}, {
    id: 3,
    title: "C'est louche !!",
    autor: "Tindano Ibrahim",
    description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam, sed diam voluptua.",
    audioSrc: podcast1,
    img: img1,
    details: ["Episode 4", "# Posté le 11 Mars 2024", "Influenceurs / Mode / Burkina Faso"]
}, {
    id: 4,
    title: "C'est louche !!",
    autor: "Tindano Ibrahim",
    description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam, sed diam voluptua.",
    audioSrc: podcast1,
    img: img1,
    details: ["Episode 4", "# Posté le 11 Mars 2024", "Influenceurs / Mode / Burkina Faso"]
}, {
    id: 5,
    title: "C'est louche !!",
    autor: "Tindano Ibrahim",
    description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam, sed diam voluptua.",
    audioSrc: podcast1,
    img: img1,
    details: ["Episode 4", "# Posté le 11 Mars 2024", "Influenceurs / Mode / Burkina Faso"]
}, {
    id: 6,
    title: "C'est louche !!",
    autor: "Tindano Ibrahim",
    description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam, sed diam voluptua.",
    audioSrc: podcast1,
    img: img1,
    details: ["Episode 4", "# Posté le 11 Mars 2024", "Influenceurs / Mode / Burkina Faso"]
}, {
    id: 7,
    title: "C'est louche !!",
    autor: "Tindano Ibrahim",
    description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam, sed diam voluptua.",
    audioSrc: podcast1,
    img: img1,
    details: ["Episode 4", "# Posté le 11 Mars 2024", "Influenceurs / Mode / Burkina Faso"]
},];

const mois = [
    "Janvier", "Février", "Mars",
    "Avril", "Mai", "Juin", "Juillet",
    "Août", "Septembre", "Octobre",
    "Novembre", "Décembre"
];

const formatDate = (date) => {
    const day = date.getDate(); // Jour du mois (de 1 à 31)
    const monthIndex = date.getMonth(); // Index du mois (de 0 à 11)
    const year = date.getFullYear(); // Année
    const hours = date.getHours(); // Heures (de 0 à 23)
    const minutes = date.getMinutes(); // Minutes (de 0 à 59)
  
    // Formater le jour avec un zéro devant s'il est inférieur à 10
    const formattedDay = day < 10 ? `0${day}` : day;
  
    // Récupérer le nom du mois correspondant à l'index
    const month = mois[monthIndex];
  
    // Concaténer les parties de la date dans le format souhaité
    return `${formattedDay} ${month} ${year}`;
}

function Podcast() {
    const [showAll, setShowAll] = useState(false);
    
    const [showAllEmssions, setShowAllEmssions] = useState(false);
    
    const podcasts = useQuery(['podcastData'], async() => {
        try{
            const res = await axios.get("/api/music/getPodcasts", {
                withCredentials:true
            })
            return res.data
        }catch(error){
            console.log(error)
        }
    })
    const visiblePodcasts = showAll ? podcasts.data : podcasts.data && podcasts.data.slice(0, 3);
    const visibleEmissions = showAllEmssions ? emissions : emissions.slice(0, 4);

    var mostListenedPodcast;
    if (podcasts.data && Array.isArray(podcasts.data)) {
        // Utilisez reduce pour trouver le podcast avec le plus grand nombre de lectures
        mostListenedPodcast = podcasts.data.reduce((maxPodcast, currentPodcast) => {
            // Si le nombre de lectures du podcast actuel est supérieur au maximum actuel,
            // mettez à jour le podcast avec le plus grand nombre de lectures
            if (currentPodcast.nbrLecture > maxPodcast.nbrLecture) {
                return currentPodcast;
            } else {
                return maxPodcast;
            }
        }, podcasts.data[0]);
    }

    console.log(podcasts.data)
    return (<div>
            <div className="all">
                <NavBar page={"podcasts"}/>
                <div className="podcast">
                        <h1>Podcast à la une</h1>
                        {
                            mostListenedPodcast && 
                                <div className="part1">
                                    <div className="left">
                                        <div className="first">
                                            {mostListenedPodcast.titre}
                                        </div>
                                        <div>
                                        {mostListenedPodcast.desc}
                                        </div>
                                        <div className="audio-container">
                                            <br/>
                                            <AudioPlayer
                                                className="audio-player"
                                                //loop
                                                src={"/api/static/music/" + mostListenedPodcast.audio}
                                                //volume={50}
                                                //volumePlacement="bottom"
                                                color="red"
                                                sliderColor="red"
                                                style={{ background: '#000' }}
                                            />
                                        </div>
                                    </div>
                                    <div className="right">
                                        <img src={"/api/static/cover/" + mostListenedPodcast.cov} alt={mostListenedPodcast.titre}/>
                                    </div>
                                </div>
                        }

                    <div className="part2">
                        <div className="header-container">
                            <span className="span1">Postés récemment</span>
                            <span>
                         <button onClick={() => setShowAll(!showAll)}>
                            {showAll ? 'Voir moins' : 'Voir tout'}
                          </button>
                        </span>
                        </div>
                        <br/>
                        <br/>
                        {
                            visiblePodcasts &&
                        visiblePodcasts.map(podcast => (<div className="podcast-item" key={podcast.idpodcasts}>
                                <div className="left-part2">
                                    <div className="logo-container">
                                        <img src={"/api/static/cover/" + podcast.cov} alt={podcast.titre}/>
                                    </div>
                                    <div className="audio-container">
                                        <div className="titre-podcast">{podcast.titre}</div>
                                        <div className="detail-podcast">{podcast.desc}</div>
                                        <div className="ico">
                                            <AudioPlayer
                                                className="audio-player"
                                                //loop
                                                src={"/api/static/music/" + podcast.audio}
                                                //volume={50}
                                                //volumePlacement="bottom"
                                                color="red"
                                                sliderColor="red"
                                                style={{ background: '#000' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="right-part2">
                                    <div className="detail">
                                        # Episode {podcast.episode}
                                    </div>
                                    <div className="detail">
                                        ## Posté le {formatDate(new Date(podcast.date))}
                                    </div>
                                    <div className="detail">
                                        # 195 lectures
                                    </div>
                                </div>
                                <div className="splitLine"></div>
                            </div>))}
                    </div>
                    {/* <div className="part3">
                        <div className="header-container">
                            <span className="span1">PODCASTS ET EMISSIONS</span>
                            <span>
                          <button onClick={() => setShowAllEmssions(!showAllEmssions)}>
                            {showAllEmssions ? 'Afficher moins' : 'Tout afficher'}
                          </button>
                        </span>
                        </div>

                        <div className="main-part">
                            {visibleEmissions.map((emission, index) => (<div className="item-emission" key={index}>
                                    <img src={emission.img} alt={emission.description}/>
                                    <div>{emission.title}</div>
                                    <div>{emission.autor}</div>
                                </div>))}
                        </div>
                    </div> */}
                </div>
            </div>
            <Footer/>
        </div>);
}

export default Podcast;
