import React, { useState, useEffect } from "react";
import "./signup.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import Loader from "../../components/loading/Loader";

function Signup() {
  // App.jsx
  const Navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [loading, setLoading] = useState(false);
  const [signupData, setSignupData] = useState({
    email: "",
    username: "",
    name:"",
    last_name:"",
    pays: "",
    password: "",
    confirmPassword: "",
  });
  const [countries, setCountries] = useState([]);
  const [errors, setErrors] = useState({
    username: "",
    name:"",
    last_name:"",
    password: "",
    confirmPassword: "",
    email: "",
    conditions: "",
  });
  const [errorSignup, setErrorSignup] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSignupData({ ...signupData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const validatePassword = () => {
    const regex = /^(?=.*[A-Z])(?=.*[@!*&%#?-_+=]).{8,}$/;

    if (regex.test(signupData.password)) {
      setIsValidPassword(true);
    } else {
      setIsValidPassword(false);
    }
  };

  const handleSignup = async (event) => {
    event.preventDefault();
    const { username, password, confirmPassword, email, name, last_name } = signupData;
    const newErrors = {};
    const regex = /^(?=.*[A-Z])(?=.*[@!*&%#?-_+=]).{8,}$/;

    if (regex.test(signupData.password)) {
      setIsValidPassword(true);
    } else {
      setIsValidPassword(false);
    }
    if (!username) {
      newErrors.username = "Veuillez remplir le champ nom";
    }

    if (!password) {
      newErrors.password = "Veuillez remplir le champ mot de passe";
    } else if (!isValidPassword) {
      newErrors.password =
        "Le mot de passe doit comporter au moins 8 caractères"+ 
        "incluant au moins une lettre majuscule et des @!*&%#?-_+=.";
    }

    if (!confirmPassword) {
      newErrors.confirmPassword =
        "Veuillez remplir le champ de confirmation du mot de passe";
    } else if (password !== confirmPassword) {
      newErrors.confirmPassword =
        "Le mot de passe de confirmation ne correspond pas au mot de passe";
    }

    if (!email) {
      newErrors.email = "Veuillez remplir le champ email";
    }
    if (!last_name) {
      newErrors.last_name = "Veuillez remplir le champ prenom";
    }
    if (!name) {
      newErrors.name = "Veuillez remplir le champ nom";
    }


    if (!conditionsChecked) {
      newErrors.conditions = "Veuillez accepter les conditions d'utilisation";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    try {
      setLoading(true);
      setErrorSignup("");
      await axios.post(
        "/api/users/register",
        signupData
      );
      await axios.post("/api/email/sendVerificationEmail", signupData);
      Navigate("/login");
    } catch (error) {
      setErrorSignup(error.response.data);
      console.log(error)
      setLoading(false);
    }
  };
  const [conditionsChecked, setConditionsChecked] = useState(false);

  const toggleConditions = () => {
    setConditionsChecked(!conditionsChecked);
  };

  useEffect(() => {
    axios
      .get("https://restcountries.com/v2/all")
      .then((response) => {
        setCountries(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
    const { value } = event.target;
    setSignupData((prevData) => ({
      ...prevData,
      pays: value,
    }));
  };
  return (
    <React.Fragment>
      <div className="signup">
        <Navbar />
        <div className="div-main">
          <div className="div-main2">
            <div className="top-div">
              <div className="left">
                <span className="text">
                  {" "}
                  <span>
                    <FontAwesomeIcon icon={faCheck} className="red-pi-check" />
                  </span>{" "}
                  DISTRIBUTION GRATUITE
                </span>
                <span className="text">
                  <span>
                    <FontAwesomeIcon icon={faCheck} className="red-pi-check" />
                  </span>{" "}
                  MISE EN LIGNE RAPIDE
                </span>
              </div>
              <div className="right">
                <span className="text">
                  <span>
                    <FontAwesomeIcon icon={faCheck} className="red-pi-check" />
                  </span>{" "}
                  DONNÉES EN TEMPS RÉELS
                </span>
                <span className="text">
                  <span>
                    <FontAwesomeIcon icon={faCheck} className="red-pi-check" />
                  </span>{" "}
                  SERVICE CLIENT EFFICACE
                </span>
              </div>
            </div>
            <div className="centered-div">
              <div className="form-container">
                {errorSignup
                  ?
                    <div 
                      style={{
                        width:'90%',
                        color:'red',
                        textAlign:'center',
                        marginLeft:'auto',
                        marginRight:'auto'
                      }}>{errorSignup.message}</div>
                  :null 
                }
                  <form action="" onSubmit={handleSignup}>
                    <div>
                        <input
                          type="email"
                          placeholder="Adresse email"
                          name="email"
                          value={signupData.email}
                          onChange={handleInputChange}
                        />
                        {errors.email && (
                          <span className="error">{errors.email}</span>
                        )}
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Nom d'artiste"
                        name="username"
                        value={signupData.username}
                        onChange={handleInputChange}
                      />
                      {errors.username && (
                        <span className="error">{errors.username}</span>
                      )}
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Nom"
                        name="name"
                        value={signupData.name}
                        onChange={handleInputChange}
                      />
                      {errors.name && (
                        <span className="error">{errors.name}</span>
                      )}
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Prenom"
                        name="last_name"
                        value={signupData.last_name}
                        onChange={handleInputChange}
                      />
                      {errors.last_name && (
                        <span className="error">{errors.last_name}</span>
                      )}
                    </div>
                    <div>
                      <select
                        className="select-form"
                        id="country"
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        name="pays"
                      >
                        <option value="">Sélectionnez un pays</option>
                        {countries.map((country) => (
                          <option
                            key={country.alpha2Code}
                            value={country.name}
                          >
                            {country.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <input
                        type="password"
                        placeholder="Mot de passe"
                        value={signupData.password}
                        onChange={handleInputChange}
                        name="password"
                        onBlur={validatePassword}
                      /><br />
                      {errors.password && (
                        <span className="error">{errors.password}</span>
                      )}
                    </div>
                    <div>
                      <input
                        type="password"
                        placeholder="confirmer mot de passe"
                        value={signupData.confirmPassword}
                        onChange={handleInputChange}
                        name="confirmPassword"
                      /><br />
                      {errors.confirmPassword && (
                        <span className="error">{errors.confirmPassword}</span>
                      )}
                    </div>
                    <div className="conditions-container">
                      <input
                        type="checkbox"
                        className="conditions-checkbox"
                        checked={conditionsChecked}
                        onChange={toggleConditions}
                      />
                      <div className="conditions-label">
                        j'accepte les<button>Conditions d'utilisations</button>
                      </div>
                    </div>
                    {
                      loading
                        ?
                          <button id="button-sigin" disabled style={{opacity:'.3'}}>
                            <Loader type="newtons-cradle" />
                          </button>
                        :
                          <button
                            id="button-sigin"
                            disabled={!conditionsChecked}
                            className={conditionsChecked ? "" : "disabled "}
                            type="submit"
                          >
                            S'inscrire
                          </button>
                    }
                  </form>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
}

export default Signup;
