import React from "react";
import "./blog.scss";
import NavBar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import {useFiltre} from "../../components/FiltreContext";
import {articles} from "./articlesdb";
import ArticleCard from "../../components/articleCard/ArticleCard";

// Blog component
const Blog = () => {
    const { filtre } = useFiltre();

    // Filtrage des articles en fonction du critère
    const filteredArticles = filtre && filtre.toLowerCase() !== 'blog'
        ? articles.filter((article) => article.type.toLowerCase() === filtre.toLowerCase())
        : articles;

    return (
        <div className="main_blog">
            <NavBar page={"blog"} />
            <div className="app">
                {/* Affichage des articles filtrés */}
                {filteredArticles.map((article, index) => (
                    <ArticleCard
                        key={index} // Assurez-vous d'avoir un identifiant unique approprié ici
                        type={article.title.length > 40 ? article.title.substring(0, 40) + " ..." : article.title}
                        title={article.title}
                        content={article.content}
                        imageCover={article.imageCover.src}
                        id={article.id} // Assurez-vous d'avoir un identifiant unique approprié ici
                        images={article.images}
                        auteur={article.auteur}
                        introduction={article.introduction}
                        date={article.date}
                        sectionTitle={article.sectionTitle}
                    />
                ))}
            </div>
            <Footer />
        </div>
    );
};

export default Blog;