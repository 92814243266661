import React from "react";
import Footer from "../../components/footer/Footer";
import './label.scss'

import NavBar from '../../components/navbar/Navbar'

import logoSpotify from '../../assets/images/logospotify.png'
import logoDeezer from '../../assets/images/logodeezer.png'
import logoAmazon from '../../assets/images/logoAmazon.png'
import jcb from '../../assets/images/jcb.avif'
import TheLord from '../../assets/images/The lord.png'
import Thugsix16teen from '../../assets/images/Congolais.png'

function Label(){
    return(
        <div className="label">
            <NavBar page='label'/>
            <div className="first-block">
                <div className="block-blur">
                    
                </div>
                <span className="title">Un Label de musique pour vous</span>
                <span>
                    En plus d'être une maison de distribution, nous sommes un label de 
                    musique qui vise à découvrir de jeunes pépites de la musique africaine 
                    et du monde et on les aide à percer dans la musique afin de révéler 
                    leur vrai potentiel.
                </span>
            </div>
            <div className="second-block">
                <div className="block-blur">

                </div>
                <div className="top">
                    <img src={TheLord} alt="the lord" />
                    <div className="texte">
                        <h2>The lord</h2>
                        <span>
                            Artiste Burkinabè c’est une des pépites de notre label, au 
                            talent prometteur il est d’une agilité sans pareille de part son
                             habilité à traversé les différents genres musicaux, auteur 
                             compositeur, il vous bercera de sa musique.
                        </span>
                    </div>
                </div>
                <div className="bottom">
                    <h2>Ecouter</h2>
                    <div className="content">
                        <img src={logoSpotify} alt="logo de spotify" />
                        <img src={logoDeezer} alt="logo de deezer" />
                        <img src={logoAmazon} alt="logo de amazon" />
                    </div>
                </div>
            </div>
            <div className="third-block">
                <div className="top">
                    <div className="texte">
                        <h2>Thugsix16teen</h2>
                        <span>
                            Congolais, ce jeune rapper prometteur de par sa verve sait manier 
                            son flot à travers les beats qu’on lui soumet un artiste qui 
                            s’adapte à toutes les symphonies qu’on lui soumet, tout simplement 
                            magnifique. Vous pouvez vous faire un avis sur cet artiste en 
                            cliquant sur ce bouton!
                        </span>
                    </div>
                    <img src={Thugsix16teen} alt="Thugsix16teen" />
                </div>
                <div className="bottom">
                    <h2>Ecouter</h2>
                    <div className="content">
                        <img src={logoSpotify} alt="logo de spotify" />
                        <img src={logoDeezer} alt="logo de deezer" />
                        <img src={logoAmazon} alt="logo de amazon" />
                    </div>
                </div>
            </div>
            <div className="fourth-block">
                <div className="block-blur">
                
                </div>
                <div className="top">
                    <img src={jcb} alt="J.C.B" />
                    <div className="texte">
                        <h2>J.C.B</h2>
                        <span>
                            Burkinabè, il se distingue de part son talent qui s’est révélé à 
                            travers son titre calibré au rythme dansant et ambiant de 
                            l’afrotrap. Vous pouvez découvrir cet artiste notamment son 
                            titre Calibré sur votre plateforme de streaming préférée.
                        </span>
                    </div>
                </div>
                <div className="bottom">
                    <h2>Ecouter</h2>
                    <div className="content">
                        <img src={logoSpotify} alt="logo de spotify" />
                        <img src={logoDeezer} alt="logo de deezer" />
                        <img src={logoAmazon} alt="logo de amazon" />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Label;