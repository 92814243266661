import React from "react";
import slogan from "../../assets/images/slogan.png";
import mission from "../../assets/images/mission.png";
import "./propos.scss";
import NavBar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

function Propos(){
  return (
    <div className="about-page">
      <NavBar page='propos'/>
      <div className="background-image" />
      <div className="content-container">
        <div className="about-us">
          <h2>Qui nous sommes</h2>
          <p>
            Lythuis Music est une plate-forme qui vous pousse à aller au delà de
            vos limittes
          </p>
        </div>
        <div className="our-mission">
          <div className="mission-image">
            <img src={mission} alt="Mission" />
          </div>
          <div className="mission-text">
            <h2>Notre mission</h2>
            <p>
              Nous sommes plus qu'un label, nous sommes plus qu'une maison
              d'édition.
            </p>
            <p>
              Nous voulons nous constituer comme les représentants de tous ces
              artistes indépendants qui se battent jour et nuit afin de faire
              connaitre leurs musiques, leurs arts à travers le monde
              entier.C'est sous cette bannière que nous voulons accompagner tous
              ces artistes et ces labels afin de leur garantir un avenir
              meilleur.
            </p>
          </div>
        </div>
        <div className="slogan">
          <div className="slogan-text">
            <h2>Lithyus, parce que vous méritez le meilleur.</h2>
          </div>
          <div className="slogan-image">
            <img src={slogan} alt="Slogan" />
          </div>
        </div>
      </div>
      <div className="newsletter">
        <h2>Abonnez-vous à notre newsletter</h2>
        <div className="newsletter-form">
          <input type="email" placeholder="Votre adresse e-mail" />
          <button>Inscription</button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Propos;
