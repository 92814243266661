import React, { useContext, useState } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../controllers/authControllers';

import DashboardSharpIcon from '@mui/icons-material/DashboardSharp';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import MenuSharpIcon from '@mui/icons-material/MenuSharp';
import NotificationsActiveSharpIcon from '@mui/icons-material/NotificationsActiveSharp';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';

import logoLithyus from "../../assets/logoLithyus.webp";
import profile from "../../assets/images/profile.png";

import './leftSide.scss';

function LeftSide({ page }) {
    const [menu, setMenu] = useState(false);
    const [search, setSearch] = useState();
    const [displayAddButton, setDisplayAddButton] = useState(false);
    const currentUser = useContext(AuthContext);
    const handleSetMenu = () => {
        setMenu(!menu)
    }
    const handleSetDisplayAddButton = () => {
        setDisplayAddButton(!displayAddButton);
    }
    const handleLogout = async () => {
        try {
            // console.log("test test test");
            await axios.post("/api/users/logout",{}, {
                withCredentials:true
            });
            localStorage.removeItem("user");
            window.location.href = '/login';
          } catch (error) {
            console.error(error);
          }
    }
    const handleChange = (event) => {
        setSearch((prev) => ({
            ...prev, [event.target.name]:event.target.value
        }));
    }
    if (page === 'notVerify'){
        return (
            <div className='Slide'>
                <div className={"leftSide " + (menu ? "active":"inactive")} >
                        <div className="TopTop">
                            <div className="top">
                                <CloseSharpIcon className="leftSideCloseIcon" onClick={handleSetMenu}/>
                                <img src={logoLithyus} alt="logo de lithyus" />
                            </div>
                            <hr />
                            <div className="bottom">
                                <NavLink to='/dashboard' style={{textDecoration:'none'}}>
                                    <div className={"box " + (page === "dashboard" ? "active" : "inactive")}>
                                        <DashboardSharpIcon className='boxIcon' />
                                        <span>Dashboard</span>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                        <div className="logout">
                            <button onClick={handleLogout}>Deconnexion</button>
                        </div>
                </div>
                <div className="navbar-content">
                    <div className="navbar">
                        <div className="navbarIcon">
                            <MenuSharpIcon style={{color:'#5f6065'}} onClick={handleSetMenu}/>
                            <NotificationsActiveSharpIcon style={{color:'#5f6065'}}/>
                        </div>
                        <div className="content">
                            <input type="search" placeholder="Search..." onChange={handleChange} value={search}/>
                            {
                                currentUser.currentUser.profile
                                    ?<img src={"/api/static/profile/" +  currentUser.currentUser.profile} alt="profil"/>
                                    :<img src={profile} alt="profile" />
                            }
                            <KeyboardArrowDownSharpIcon className='rightSideNavbarContentIcon' onClick={handleSetDisplayAddButton}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }else{

        return (
            <div className='Slide'>
                <div className={"leftSide " + (menu ? "active":"inactive")} >
                        <div className="TopTop">
                            <div className="top">
                                <CloseSharpIcon className="leftSideCloseIcon" onClick={handleSetMenu}/>
                                <img src={logoLithyus} alt="logo de lithyus" />
                            </div>
                            <hr />
                            <div className="bottom">
                                <NavLink to='/dashboard' style={{textDecoration:'none'}}>
                                    <div className={"box " + (page === "dashboard" ? "active" : "inactive")}>
                                        <DashboardSharpIcon className='boxIcon' />
                                        <span>Dashboard</span>
                                    </div>
                                </NavLink>
                                {/* <NavLink to='' style={{textDecoration:'none'}}>
                                    <div className={"box " + (page === "objectifs" ? "active" : "inactive")}>
                                        <CrisisAlertIcon className='boxIcon'/>
                                        <span>objectifs</span>
                                    </div>
                                </NavLink> */}
                                    {/* <NavLink to='' style={{textDecoration:'none'}}>
                                        <div className={"box " + (page === "graphiques" ? "active" : "inactive")}>
                                            <SignalCellularAltIcon className='boxIcon'/>
                                            <span>Graphiques</span>
                                        </div>
                                    </NavLink> */}
                                    <NavLink to='/settings' style={{textDecoration:'none'}}>
                                        <div className={"box " + (page === "parametres" ? "active" : "inactive")}>
                                            <SettingsSuggestIcon className='boxIcon' />
                                            <span>Paramètres</span>
                                        </div>
                                    </NavLink>
                                    {/* <NavLink to='' style={{textDecoration:'none'}}>
                                        <div className={"box " + (page === "messages" ? "active" : "inactive")}>
                                            <MailOutlineIcon  className='boxIcon'/>
                                            <span>Messages</span>
                                        </div>
                                    </NavLink> */}
                                    <NavLink to='/retrait' style={{textDecoration:'none'}}>
                                        <div className={"box " + (page === "retrait" ? "active" : "inactive")}>
                                            <CreditScoreIcon className='boxIcon'/>
                                            <span>Retrait</span>
                                        </div>
                                    </NavLink>
                            </div>
                        </div>
                        <div className="logout">
                            <button onClick={handleLogout}>Deconnexion</button>
                        </div>
                </div>
                <div className="navbar-content">
                    <div className="navbar">
                        <div className="navbarIcon">
                            <MenuSharpIcon style={{color:'#5f6065'}} onClick={handleSetMenu}/>
                            <NotificationsActiveSharpIcon style={{color:'#5f6065'}}/>
                        </div>
                        <NavLink to='/addMusic' className='addMusicPc'>Ajouter une musique</NavLink>
                        <div className="content">
                            <input type="search" placeholder="Search..." onChange={handleChange}/>
                            {
                                currentUser.currentUser.profile
                                    ?<img src={"/api/static/profile/" +  currentUser.currentUser.profile} alt="profil"/>
                                    :<img src={profile} alt="profile" />
                            }
                            <KeyboardArrowDownSharpIcon className='rightSideNavbarContentIcon' onClick={handleSetDisplayAddButton}/>
                        </div>
                    </div>
                </div>
                <div className={"addMusicTel " + (displayAddButton ? "active":"inactive")}>
                    <NavLink to='/addMusic' className='button'>Ajouter une musique</NavLink>
                </div>
            </div>
        )
    }
}

export default LeftSide;