import React, { useState } from "react"
import moment from 'moment';
import axios from "axios";

import AutorenewSharpIcon from '@mui/icons-material/AutorenewSharp';
import DoNotDisturbAltSharpIcon from '@mui/icons-material/DoNotDisturbAltSharp';
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import CloseIcon from '@mui/icons-material/Close';

import Loader from "../loading/Loader";
import './music.scss'


function Music({music}){
    const [waitDelete, setWaitDelete] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [askDelete, setAskDelete] = useState(false);
    const handleShowMore = (event) => {
        setShowMore(!showMore);
    }
    function handleDownload(e) {
        e.preventDefault();
      
        fetch("/api/static/cover/" + music.music_cov)
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = music.music_cov;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          })
          .catch((error) => console.log(error));
    }
    const handleAskDelete = () => {
        setAskDelete(!askDelete)
    }
    const handleDelete = async(event) => {
        event.preventDefault();
        try{
            setWaitDelete(true);
            await axios.delete(`/api/music/deleteMusic?musicId=${music.idmusic}`, {
                withCredentials:true
            })
            setWaitDelete(false);
            window.location.reload();
        }catch(error){
            console.log(error)
        }
    }
    return (
        <div className="add_music">
            {
                askDelete
                    &&
                <div className="reject">
                    <CloseIcon className="icon" onClick={handleAskDelete}/>
                    <h3 style={{textAlign:'center'}}>Voulez vous vraiment supprimer la musique {music.music_title}</h3>
                    <div
                        style={{
                            width:'90%',
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'space-between'
                        }}
                    >
                        <button
                            style={{
                                backgroundColor:'green',
                                width:'100px',
                                border:'none',
                                borderRadius:'8px'
                            }}
                            onClick={handleAskDelete}
                        >Non</button>
                        <button
                            style={{
                                backgroundColor:'red',
                                width:'100px',
                                border:'none',
                                borderRadius:'8px'
                            }}
                            onClick={handleDelete}
                        >Oui</button>
                    </div>
                </div>

            }
            <div className="box" key={music.idmusic} onClick={handleShowMore}>
                <div className="titre">
                    <img src={"/api/static/cover/" +  music.music_cov} alt={music.music_cov} />
                    <div className="content">
                        <h4>{music.music_title}</h4>
                        <span>{music.nom_artiste}</span>
                    </div>
                </div>
                <div className="date">
                    {moment(music.date_publication).format('YYYY-MM-DD')}
                </div>
                    {music.status === 0
                        ?
                            <div className="status">
                                <div className="statusNoIcon">
                                    <DoNotDisturbAltSharpIcon className="icon"/>
                                </div>
                                <span>Vérification</span>
                            </div>
                        : music.status === 1
                            ?
                                <div className="status">
                                    <div className="statusInIcon">
                                        <AutorenewSharpIcon className="icon"/>
                                    </div>
                                    <span>En cours</span>
                                </div>
                            : music.status === 2
                                ?
                                    <div className="status">
                                        <div className="statusDoIcon">
                                            <CheckSharpIcon className="icon"/>
                                        </div>
                                        <span>Publiée</span>
                                    </div>
                                :
                                <div className="status">
                                    <div className="statusRejectIcon">
                                        <CloseIcon className="icon"/>
                                    </div>
                                    <span>Réfusée</span>
                                </div>
                    }
                    <div className="promute">
                        {
                            music.idpromotion
                            ? <span>YES {music.payement != 1 ? <span style={{color:'red'}}>(Non payé)</span> : <span style={{color:'green'}}>(Payé)</span>}</span>
                            : <span>NO</span>
                        }
                    </div>
            </div>
            {
                showMore
                    ?
                        <div className="MusicShowMore">
                            <table style={{ borderCollapse: "collapse" }}>
                                <tbody>
                                    <tr>
                                        <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                            <div>Artistes</div>
                                            <div>{music.nom_artiste}</div>
                                        </td>
                                        <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                            <div>Titre</div>
                                            <div>{music.music_title}</div>
                                        </td>
                                        <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                            <div>Genre</div>
                                            <div>{music.music_genre}</div>
                                        </td>
                                        <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                            <div>Langue</div>
                                            <div>{music.music_lang}</div>
                                        </td>
                                        <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                            <div>Audio</div>
                                            <div className="audio">
                                                <audio controls>
                                                    <source src={"/api/static/music/" + music.music_file} type="audio/wav" />
                                                </audio>
                                            </div>
                                        </td>
                                        <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                            <div>Couverture</div>
                                            <div className="imgTelecharge">
                                                <img src={"/api/static/cover/" + music.music_cov} alt={music.music_cov} />
                                                <button onClick={handleDownload} style={{backgroundColor:'transparent', textDecoration:'underline'}}>
                                                    Telecharger
                                                </button>
                                            </div>
                                        </td>
                                        <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                            <div>Date d'envoie</div>
                                            <div>{moment(music.date_enregistrement).format('YYYY-MM-DD')}</div>
                                        </td>
                                        <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                            <div>Date de publication</div>
                                            <div>{moment(music.date_publication).format('YYYY-MM-DD')}</div>
                                        </td>
                                        <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                            <div>
                                                Type de Promotion
                                            </div>
                                            <div>
                                                {
                                                    music.type
                                                }
                                            </div>
                                        </td>
                                        <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                        {
                                            waitDelete
                                                ?<button disabled>
                                                <Loader type="newtons-cradle"/>
                                                </button>
                                                :<button onClick={handleAskDelete}>Supprimer</button>
                                        }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    :null
            }
            <hr />
        </div>
    )
}

export default Music;