import React from "react"
import { Link } from "react-router-dom";
import NavBar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import "./politiqueVentes.scss"

function PolitiqueVentes() {
    return(
        <div className="PolitiqueVentes">
             <NavBar />
             <div className="corps">
                <div className="title">
                    <span>Politique de Ventes et de remboursement</span>
                </div>
                <div className="body">
                   <p>
                   Le site Web situé à l'adresse lithyusmusic.com (le « Site ») ainsi que les marques de commerce Lithyus Music, Lithyus Music Distribution, Lithyus Music Distribution, Lithyus Music Studios sont des œuvres protégées par le droit d'auteur appartenant à Lithyus Music Group, LLC (« Société », « nous », « notre » et « nos »).  Certaines fonctionnalités du site peuvent être soumises à des directives, conditions ou règles supplémentaires, qui seront publiées sur le site en relation avec ces fonctionnalités.
                   </p>
                   <span>But de cette politique</span>
                   <p>
                   Cette politique a pour objectif d’informer les utilisateurs de notre site web sur les données personnelles que nous collectons, leur utilisation, et les droits des utilisateurs. Elle fonctionne en parallèle avec nos conditions générales d’utilisation. Elle a également pour objectif d’informer les utilisateurs de notre site web que nous ne fournissons pas de compensation pour les produits ou services achetés et que nous n’acceptons pas les retours. Elle fonctionne aussi en parallèle avec nos conditions générales d’utilisation.
                   </p>
                   <span>Données personnelles collectées</span>
                   <p>
                   Nous collectons les données personnelles suivantes :
                    <br />●	Informations de contact (nom, adresse e-mail, numéro de téléphone)
                    <br />●	Informations de paiement (moyen de paiement, adresse de facturation)
                    <br />●	Historique des achats

                   </p>
                   <span>Conditions</span>
                   <p>
                   Voici les principales conditions de notre politique de non-remboursement :
                    <br />1.	Aucun remboursement : Une fois qu’un client a finalisé un achat, nous ne procédons à aucun remboursement, que ce soit en espèces ou sous forme de crédit.
                    <br />2.	Aucun retour : Les produits achetés ne peuvent pas être retournés pour un remplacement ou un remboursement.
                    <br />3.	Exceptions : Dans des cas exceptionnels et sous la discrétion de l’entreprise (par exemple, en cas de manquement à l’exécution d’une tâche ou d’un service), nous pouvons envisager des solutions alternatives telles que l’échange d’un service défectueux contre un produit similaire.

                    </p>
                    <span>Produits concernés</span>
                    <p>
                    Notre politique de non-remboursement s’applique aux produits suivants :
                    <br />●	Tous les articles achetés sur notre site web, qu’ils soient physiques ou numériques.
                    <br />●	Les services rendus par notre entreprise.

                    </p>
                    <span>Utilisation des données</span>
                    <p>
                    Nous utilisons ces données pour :
                    <br />●	Traiter les commandes et les paiements
                    <br />●	Livrer les produits achetés
                    <br />●	Communiquer avec les clients concernant leurs achats
                    <br />●	Améliorer nos services

                    </p>
                    <span>Accès aux données</span>
                    <p>
                    Les données collectées sont accessibles uniquement aux membres de notre équipe autorisés à traiter les commandes et à fournir un support client.
                    </p>
                    <span>Droits des utilisateurs</span>
                    <p>
                    Les utilisateurs ont les droits suivants :
                    <br />●	Accéder à leurs données personnelles
                    <br />●	Demander la rectification ou la suppression de leurs données
                    <br />●	S’opposer au traitement de leurs données

                    </p>
                    <span>Cookies</span>
                    <p>
                    Notre site web utilise des cookies pour améliorer l’expérience utilisateur. Consultez notre politique de cookies pour plus d’informations.
                    </p>
                    <span>Lois applicables</span>
                    <p>
                    Cette politique est conforme au Règlement général sur la protection des données (RGPD) en Amérique du Nord et en Europe et par les lois des pays sur lesquels ses offres sont desservies.
                    </p>
                    <span>Contact</span>
                    <p>
                    Si les utilisateurs ont des questions concernant notre politique de vente et remboursement, ils peuvent nous contacter via les coordonnées fournies sur notre site web.
                    </p>


                </div>
             </div>
             <Footer/>
        </div>
    )
    

}
export default PolitiqueVentes;