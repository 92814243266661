import Footer from '../../components/footer/Footer'
import NavBar from '../../components/navbar/Navbar'

import SendSharpIcon from '@mui/icons-material/SendSharp';

import image1 from '../../assets/images/homme_avec_casque.png'
import facebook from '../../assets/images/facebook.png'
import twitter from '../../assets/images/Logo_Twitter.png'
import instagram from '../../assets/images/logo_insta.png'
import email from '../../assets/images/email.jpg'
import tel from '../../assets/images/telephone.png'

import './contact.scss'

function Contact(){
    return(
        <div className="contact">
            <NavBar page='contact'/>
            <div className="tel">
                <div className="first-block">
                    <div className="cover-first-block">

                    </div>
                    <span>Contactez nous pour une assistance clientèle hors du commun</span>
                </div>
                <div className="second-block">
                    <div className="top">
                        <img src={image1} alt="homme avec casque de musique" />
                    </div>
                    <div className="bottom">
                        <h2>Nos contacts</h2>
                        <div className="content">
                            <a href="https://www.facebook.com/lithyusmusic">
                                <div className="box">
                                    <div className="icon">
                                        <img src={facebook} alt="logo de facebook" />
                                    </div>
                                    <div className="texte">
                                        <h2>Facebook</h2>
                                        <span>@LithyusMusic</span>
                                    </div>
                                    <div className="send">
                                        <SendSharpIcon className='SendSharpIcon' />
                                    </div>
                                </div>
                            </a>
                            <a href="https://twitter.com/LithyusMusic">
                                <div className="box">
                                    <div className="icon">
                                        <img src={twitter} alt="logo de twitter" />
                                    </div>
                                    <div className="texte">
                                        <h2>Twitter</h2>
                                        <span>@LithyusMusic</span>
                                    </div>
                                    <div className="send">
                                        <SendSharpIcon className='SendSharpIcon' />
                                    </div>
                                </div>
                            </a>
                            <a href="https://www.instagram.com/lithyusmusic/">
                                <div className="box">
                                    <div className="icon">
                                        <img src={instagram} alt="logo d'instagram" />
                                    </div>
                                    <div className="texte">
                                        <h2>Instagram</h2>
                                        <span>@LithyusMusic</span>
                                    </div>
                                    <div className="send">
                                        <SendSharpIcon className='SendSharpIcon' />
                                    </div>
                                </div>          
                            </a>
                            <a href="mailto:infos@lithyusmusic.com">
                                <div className="box">
                                    <div className="icon">
                                        <img src={email} alt="email" />
                                    </div>
                                    <div className="texte">
                                        <h2>Email</h2>
                                        <span>infos@lithyusmusic.com</span>
                                    </div>
                                    <div className="send">
                                        <SendSharpIcon className='SendSharpIcon' />
                                    </div>
                                </div>
                            </a>
                            <a href="tel:+1 725 224 8456">
                                <div className="box">
                                    <div className="icon">
                                        <img src={tel} alt="telephone" />
                                    </div>
                                    <div className="texte">
                                        <h2>Téléphone</h2>
                                        <span>+1 725 224 8456</span>
                                    </div>
                                    <div className="send">
                                        <SendSharpIcon className='SendSharpIcon'/>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ordi">
                <div className="ordi-img">
                </div>
                <div className="block">
                    <div className="top">
                        Contactez nous pour une assistance clientèle hors du commun
                    </div>
                    <div className="bottom">
                        <div className="left">
                            <img src={image1} alt="homme en casque" />
                        </div>
                        <div className="right">
                            <h2>Nos contacts</h2>
                            <div className="content">
                            <a href="https://www.facebook.com/lithyusmusic">
                                <div className="box">
                                    <div className="icon">
                                        <img src={facebook} alt="logo de facebook" />
                                    </div>
                                    <div className="texte">
                                        <h2>Facebook</h2>
                                        <span>@LithyusMusic</span>
                                    </div>
                                    <div className="send">
                                        <SendSharpIcon className='SendSharpIcon' />
                                    </div>
                                </div>
                            </a>
                            <a href="https://twitter.com/LithyusMusic">
                                <div className="box">
                                    <div className="icon">
                                        <img src={twitter} alt="logo de twitter" />
                                    </div>
                                    <div className="texte">
                                        <h2>Twitter</h2>
                                        <span>@LithyusMusic</span>
                                    </div>
                                    <div className="send">
                                        <SendSharpIcon className='SendSharpIcon' />
                                    </div>
                                </div>
                            </a>
                            <a href="https://www.instagram.com/lithyusmusic/">
                                <div className="box">
                                    <div className="icon">
                                        <img src={instagram} alt="logo d'instagram" />
                                    </div>
                                    <div className="texte">
                                        <h2>Instagram</h2>
                                        <span>@LithyusMusic</span>
                                    </div>
                                    <div className="send">
                                        <SendSharpIcon className='SendSharpIcon' />
                                    </div>
                                </div>          
                            </a>
                            <a href="mailto:infos@lithyusmusic.com">
                                <div className="box">
                                    <div className="icon">
                                        <img src={email} alt="email" />
                                    </div>
                                    <div className="texte">
                                        <h2>Email</h2>
                                        <span>infos@lithyusmusic.com</span>
                                    </div>
                                    <div className="send">
                                        <SendSharpIcon className='SendSharpIcon' />
                                    </div>
                                </div>
                            </a>
                            <a href="tel:+1 725 224 8456">
                                <div className="box">
                                    <div className="icon">
                                        <img src={tel} alt="telephone" />
                                    </div>
                                    <div className="texte">
                                        <h2>Téléphone</h2>
                                        <span>+1 725 224 8456</span>
                                    </div>
                                    <div className="send">
                                        <SendSharpIcon className='SendSharpIcon'/>
                                    </div>
                                </div>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Contact;