import React, { useState } from "react"
import moment from 'moment';
import axios from "axios";

import AutorenewSharpIcon from '@mui/icons-material/AutorenewSharp';
import DoNotDisturbAltSharpIcon from '@mui/icons-material/DoNotDisturbAltSharp';
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import CloseIcon from '@mui/icons-material/Close';
import './music.scss'


function Music({music}){
    const [more, setMore] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const handleShowMore = (event) => {
        setShowMore(!showMore);
    }

    const handleEnCours = async() => {
        try{
            const res = await axios.get(`/api/music/makeEnCours?musicId=${music.idmusic}`, {
                withCredentials:true
            })
            console.log(res)
        }catch(error){

        }
        setMore(!more);
        window.location.reload();
    }
    const [reject, setReject] = useState(false);
    const handleReject = async() => {
        setReject(!reject);
    }
    const handlePublie = async() => {
        try{
            const res = await axios.get(`/api/music/makePublie?musicId=${music.idmusic}`, {
                withCredentials:true
            })
            console.log(res)
        }catch(error){

        }
        window.location.reload();
        setMore(!more);
    }

    function handleDownload(e) {
        e.preventDefault();
      
        fetch("/api/static/cover/" + music.music_cov)
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = music.music_cov;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          })
          .catch((error) => console.log(error));
      }

    const [checkedValues, setCheckedValues] = useState([]);

    const handleCheckboxChange = (event) => {
        const value = event.target.value;
        const isChecked = event.target.checked;

        if (isChecked) {
        setCheckedValues([...checkedValues, value]);
        } else {
        setCheckedValues(checkedValues.filter(item => item !== value));
        }
    };
    const handleSendReject = async(event) => {
        event.preventDefault();
        try{
            await axios.post(`/api/music/musicReject?musicId=${music.idmusic}`, {checkedValues}, {
                withCredentials:true
            })
            alert("Mise à jour de la musique reussie.")
            setReject(!reject);
        }catch(error){
            console.log(error)
        }
    }
    return (
        <div className="add_music">
            {
                reject  && 
                    <div className="reject">
                        <CloseIcon className="icon" onClick={handleReject}/>
                        <h3>Choisissez le raisons de refus</h3>
                        <form action="" onSubmit={handleSendReject}>
                            <div>
                                <label htmlFor="reject1">
                                    <input
                                        type="checkbox"
                                        className="box-reject"
                                        id="reject1"
                                        value="Cover ne repondant pas les dimensions voulues"
                                        onChange={handleCheckboxChange}
                                        checked={checkedValues.includes('Cover ne repondant pas les dimensions voulues')}
                                    />
                                    <span className="custom-checkbox"></span>
                                    Cover ne respecte pas les dimensions voulues
                                </label>
                            </div>
                            <div>
                                <label htmlFor="reject2">
                                    <input
                                        type="checkbox"
                                        className="box-reject"
                                        id="reject2" 
                                        value="Format audio ne repeste pas les critères exigés"
                                        onChange={handleCheckboxChange}
                                        checked={checkedValues.includes('Format audio ne repeste pas les critères exigés')}
                                    />
                                    <span className="custom-checkbox"></span>
                                    Format audio ne repeste pas les critères exigés
                                </label>
                            </div>
                            <div>
                                <label htmlFor="reject3">
                                    <input
                                        type="checkbox"
                                        className="box-reject"
                                        id="reject3" 
                                        value="Samples audio pas légaux"
                                        onChange={handleCheckboxChange}
                                        checked={checkedValues.includes('Samples audio pas légaux')}
                                    />
                                    <span className="custom-checkbox"></span>
                                    Samples audio pas légaux
                                </label>
                            </div>
                            <div>
                                <label htmlFor="reject4">
                                    <input
                                        type="checkbox"
                                        className="box-reject"
                                        id="reject4" 
                                        value="Musique déjà publiée"
                                        onChange={handleCheckboxChange}
                                        checked={checkedValues.includes('Musique déjà publiée')}
                                    />
                                    <span className="custom-checkbox"></span>
                                    Musique déjà publiée
                                </label>
                            </div>
                            <button type="submit" onClick={handleSendReject}>Valider</button>
                        </form>
                    </div>
            }
            <div className="box" key={music.idmusic} onClick={handleShowMore}>
                <div className="titre">
                    <img src={"/api/static/cover/" +  music.music_cov} alt={music.music_cov} />
                    <div className="content">
                        <h4>{music.music_title}</h4>
                        <span>{music.nom_artiste}</span>
                    </div>
                </div>
                <div className="date">
                    {moment(music.date_publication).format('YYYY-MM-DD')}
                </div>
                    {music.status === 0
                        ?
                            <div className="status">
                                <div className="statusNoIcon">
                                    <DoNotDisturbAltSharpIcon className="icon"/>
                                </div>
                                <span>Vérification</span>
                            </div>
                        : music.status === 1
                            ?
                                <div className="status">
                                    <div className="statusInIcon">
                                        <AutorenewSharpIcon className="icon"/>
                                    </div>
                                    <span>En cours</span>
                                </div>
                            : music.status === 2
                                ?
                                    <div className="status">
                                        <div className="statusDoIcon">
                                            <CheckSharpIcon className="icon"/>
                                        </div>
                                        <span>Publiée</span>
                                    </div>
                                :
                                <div className="status">
                                    <div className="statusRejectIcon">
                                        <CloseIcon className="icon"/>
                                    </div>
                                    <span>Réfusée</span>
                                </div>
                    }
                    <div className="promute">
                        {
                            music.idpromotion
                            ? <span>YES {music.payement != 1 ? <span style={{color:'red'}}>(Non payé)</span> : <span style={{color:'green'}}>(Payé)</span>}</span>
                            : <span>NO</span>
                        }
                    </div>
            </div>
            {
                showMore
                    ?
                        <div className="MusicShowMore">
                            <table style={{ borderCollapse: "collapse" }}>
                                <tbody>
                                    <tr>
                                        <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                            <div>Artistes</div>
                                            <div>{music.nom_artiste}</div>
                                        </td>
                                        <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                            <div>Titre</div>
                                            <div>{music.music_title}</div>
                                        </td>
                                        <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                            <div>Genre</div>
                                            <div>{music.music_genre}</div>
                                        </td>
                                        <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                            <div>Langue</div>
                                            <div>{music.music_lang}</div>
                                        </td>
                                        <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                            <div>Audio</div>
                                            <div className="audio">
                                                <audio controls>
                                                    <source src={"/api/static/music/" + music.music_file} type="audio/wav" />
                                                </audio>
                                            </div>
                                        </td>
                                        <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                            <div>Couverture</div>
                                            <div className="imgTelecharge">
                                                <img src={"/api/static/cover/" + music.music_cov} alt={music.music_cov} />
                                                <button onClick={handleDownload} style={{backgroundColor:'transparent', textDecoration:'underline'}}>
                                                    Telecharger
                                                </button>
                                            </div>
                                        </td>
                                        <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                            <div>Date d'envoie</div>
                                            <div>{moment(music.date_enregistrement).format('YYYY-MM-DD')}</div>
                                        </td>
                                        <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                            <div>Date de publication</div>
                                            <div>{moment(music.date_publication).format('YYYY-MM-DD')}</div>
                                        </td>
                                        <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                            <div>
                                                Type de Promotion
                                            </div>
                                            <div>
                                                {
                                                    music.type
                                                }
                                            </div>
                                        </td>
                                        <td style={{ border: "1px solid #5f6065", padding: "5px" }}>
                                            <button className="enCours" onClick={handleEnCours}>En cours ?</button>
                                            <div
                                                style={{
                                                    display:'flex',
                                                    gap:'10px'
                                                }}
                                            >
                                                <button className="publie" onClick={handlePublie}>Publié ?</button>
                                                <button className="refuse" onClick={handleReject}>Réjéter ?</button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    :null
            }
            <hr />
        </div>
    )
}

export default Music;